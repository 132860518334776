 import axios from 'axios'
 import qs from 'qs';

 // 创建 axios 实例
 const service = axios.create({
     baseURL: '/api', // api base_url
     timeout: 6000 // 请求超时时间
 })

 const err = (error) => {
     if (error.response) {
         const data = error.response.data
         if (error.response.status === 500) {
             if (data.message.length > 0) {
                 message.error(data.message)
             }
         }
     }
     return Promise.reject(error)
 }

 // request interceptor
 service.interceptors.request.use(config => {
     if (config.method.toLowerCase() === 'post') {
         config.data = qs.stringify(config.data)
         config.headers['content-type'] = 'application/x-www-form-urlencoded'
     }
     return config
 }, err)

 /**
  * response interceptor
  * 所有请求统一返回
  */
 service.interceptors.response.use((response) => {
     if (response.request.responseType === 'blob') {
         return response
     }
     const code = response.data.code
     if (code > 0) {
         message.error(response.data.message)
         return response.data
     } else {
         return response.data
     }
 }, err)

 export default service