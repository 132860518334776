import Vue from 'vue'
import Vuex from 'vuex'
import bookApi from '@/apis/book'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        bookInfo: null,
        chapterList: null,
        pageList: null,
        pageNo: 0,
        chapterCurr: null,
        needJump: false
    },
    mutations: {
        SetBookInfo(state, pyaload) {
            this.state.bookInfo = pyaload
        },
        SetChapterList(state, pyaload) {
            this.state.chapterList = pyaload
        },
        SetPageList(state, pyaload) {
            this.state.pageList = pyaload
        },
        SetPageNo(state, pyaload) {
            this.state.pageNo = pyaload
        },
        SetChapterCurr(state, pyaload) {
            this.state.chapterCurr = pyaload
        },
        SetNeedJump(state, pyaload) {
            this.state.needJump = pyaload
        },
    },
    actions: {
        async init({ commit }, pyaload) {
            if (this.state.bookInfo) {
                return
            }
            let remoteData = await bookApi.detail(pyaload.bid)
            document.title = remoteData.bookName
            commit('SetBookInfo', {
                id: remoteData.id,
                bookName: remoteData.bookName,
                isbn: remoteData.isbn
            })
            commit('SetChapterList', remoteData.listBookChapter)
            commit('SetPageList', remoteData.listBookPage)
            //-------------------------
            console.log('默认章节处理', pyaload.cid);
            let hasCid = false
            if (pyaload.cid) {
                let cc = remoteData.listBookChapter.filter(f => f.id == pyaload.cid)
                if (cc.length > 0) {
                    cc = cc[0]
                    hasCid = true
                    console.log('默认章节为:', cc);
                    commit('SetPageNo', cc.beginPage)
                    commit('SetChapterCurr', cc)
                }
            }
            if (!hasCid) {
                console.log('默认章节:NONE');
                commit('SetPageNo', 0)
                commit('SetChapterCurr', remoteData.listBookChapter[0])
            }

        }

    }
})