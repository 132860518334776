<template>
    <div id="app" :class="!isMobile?'pc':''">
        <keep-alive>
            <router-view></router-view>
        </keep-alive>
    </div>
</template>

<script>
export default {
    name: "app", 
    data() {
        return {
            isMobile: null,
        };
    },
    created() {
        this.isMobile = this.isMobileF();
    },
    methods: {
        isMobileF() {
            let info = navigator.userAgent;
            console.log("navinfo", info);
            let agents = [
                "Android",
                "iPhone",
                "SymbianOS",
                "Windows Phone",
                "iPod",
                "iPad",
            ];
            for (let i = 0; i < agents.length; i++) {
                if (info.indexOf(agents[i]) >= 0) return true;
            }
            return false;
        },
    },
};
</script>

<style>
* {
    padding: 0;
    margin: 0;
    border: 0;
}
#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
}
.pc {
    max-width: 1000px;
}
</style>
