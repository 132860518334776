import Vue from 'vue'
import VueRouter from 'vue-router'
import BookPage from '../pages/BookPage.vue'
import BookChapter from '../pages/BookChapter.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: '/bp/:bid',
    name: 'BookPage',
    component: BookPage
  },
  {
    path: '/bp/:bid/:cid',
    name: 'BookPage2',
    component: BookPage
  },
  {
    path: '/bc/:bid',
    name: 'BookChapter',
    component: BookChapter
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

 

export default router