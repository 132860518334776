<template>
    <div class="pageMain" v-if="chapterCurr">
        <div class="page1">
            <div class="title">
                <div class="left"> {{chapterCurr.chapterName}}</div>
                <div class="right">当前第{{pageNo + 1}}/{{pageList.length}}</div>
            </div>

            <div class="swiper-container" id="swiper2">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="item in pageList" :key="item.id">

                        <img :data-src="item.url" alt="" class="swiper-lazy">
                    </div>
                </div>
            </div>
        </div>

        <div class="menu">
            <router-link :to="{name:'BookChapter',params:{id:id}}">
                <img src="@assets/img/icon_menu.png" alt="">
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Swiper from "swiper";
export default {
    name: "BookPage",
    computed: mapState([
        "pageList",
        "chapterList",
        "pageNo",
        "chapterCurr",
        "needJump",
    ]),

    data() {
        return {
            id: this.$route.params.bid,
            startIndex: 0,
            swiper: null,
            first: true,
        };
    },
    async created() {
        await this.$store.dispatch("init", {
            bid: this.id,
            cid: this.$route.params.cid,
        });

        this.initSwiper();
    },
    watch: {
        pageNo(v) {
            console.log("页码改变WATCH ", v, this.needJump);
            if (v > 0) {
                let l = this.chapterList
                    .filter((f) => f.beginPage <= v)
                    .sort((a, b) => b.beginPage - a.beginPage)[0];
                console.log("欲改变当前章节", l.id,this.chapterCurr.id);
                if (l.id !== this.chapterCurr.id) {
                    this.SetChapterCurr(l);
                }
            }
            if (this.needJump) {
                console.log("页码跳转", v);
                this.SetNeedJump(false);
                this.swiper.slideTo(v, 0);
            }
        },
    },
    methods: {
        ...mapMutations(["SetPageNo", "SetNeedJump", "SetChapterCurr"]),
        initSwiper() {
            let _this = this;
            if (!this.swiper) {
                console.log("初始SWIPER", _this.pageNo);
                this.swiper = new Swiper("#swiper2", {
                    observer: true,
                    initialSlide: _this.pageNo,
                    lazy: {
                        loadPrevNext: true,
                    },
                    on: {
                        touchEnd() {},
                        observerUpdate: function () {
                            console.log("observer监测到了不可描述的事情发生");
                        },
                        slideChangeTransitionEnd: function () {
                            console.log(
                                ` 当前页码:${_this.pageNo} 当前activiInde:${this.activeIndex}`
                            );
                            _this.SetPageNo(this.activeIndex);
                        },
                    },
                });
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.title {
    height: 1.2rem;
    line-height: 1.2rem;
    font-size: 0.32rem;
    color: #999999;
    display: flex;
    flex-flow: row nowrap;
    padding: 0 0.4rem;
    .left {
        flex: 1;
        text-align: left;
    }
    .right {
        width: 180px;
        text-align: right;
    }
}

.swiper-slide {
    img {
        width: 100%;
    }
}

.menu {
    position: fixed;
    bottom: 1.73333rem;
    right: 0.32rem;
    width: 1.17333rem;
    height: 1.17333rem;
    z-index: 1;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}
</style>
