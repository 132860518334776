<template>

    <div class="menuMain">

        <div class="title1">目录</div>

        <el-tree :data="treeList" default-expand-all node-key="id" @node-click="handleNodeClick" class="treeList"></el-tree>

        <div class="return">
            <router-link :to="{name:'BookPage',params:{id:id}}">
                <img src="@assets/img/icon_return.png" alt="">
            </router-link>
        </div>

    </div>

</template>

<script>
import router from "@/router";
import { mapState, mapMutations } from "vuex";

export default {
    name: "BookPage",
    props: {},
    data() {
        return {
            id: this.$route.params.bid,
            treeList: null,
        };
    },
    async created() {
        await this.$store.dispatch("init", { bid: this.id });
        this.treeList = this.arrayToTree(this.$store.state.chapterList);
    },
    methods: {
        ...mapMutations(["SetPageNo", "SetChapterCurr", "SetNeedJump"]),
        //列表转成TREE
        arrayToTree(list, parentId = 0) {
            return list
                .filter((item) => item.parentId === parentId)
                .map((item) => ({
                    ...item,
                    label: item.chapterName,
                    children: this.arrayToTree(list, item.id),
                }));
        },
        //目录节点点击处理
        handleNodeClick(data) {
            if (!data.children.length) {
                this.SetNeedJump(true);
                this.SetPageNo(data.beginPage);
                this.SetChapterCurr(data);

                router.push({ name: "BookPage", params: { bid: this.id } });
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
.menuMain {
    padding-top: 0.2rem;
    width: 100%;
    min-height: 100vh;
    background: #f6f5f1;
    box-sizing: border-box;
    padding-left: 0.3rem;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    .title1 {
        font-size: .4rem;
    }
    .el-tree {
        background: none;
        .el-tree-node {
            padding-top: 0.15rem;
            padding-bottom: 0.15rem;
            .el-tree-node__label {
                font-size: 0.4rem;
                height: auto;
                padding-top: 0.15rem;
                padding-bottom: 0.15rem;
            }
            .el-tree-node__expand-icon {
                font-size: 0.42rem;
            }
        }
    }

    .return {
        position: fixed;
        top: calc(50vh - 70px);
        right: 0;

        width: 70px;
        height: 140px;

        img {
            width: 70px;
            height: 140px;
        }
    }
}
</style>
